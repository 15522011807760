import React, { useContext, useEffect, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import axios from 'axios'

import { CognitoUserContext } from '../App'
import config from '../config'

interface Props {
  close: () => void
  acceptedFiles: File[]
  fileRejections: FileRejection[]
  folderName: string
  open: boolean
}

const UploadDialog: React.FC<Props> = (props: Props) => {
  const cognitoUser = useContext(CognitoUserContext)

  const [status, setStatus] = useState<'initial' | 'uploading' | 'completed' | 'error'>('initial')

  const close = () => {
    props.close()
  }

  const onClickOK = async () => {
    setStatus('uploading')

    const userAttributes = await Auth.userAttributes(cognitoUser)
    const email = userAttributes.find((value) => value.getName() === 'email')

    Promise.all(
      props.acceptedFiles.map(async (file: File) => {
        return axios
          .post<{
            presignedUrl: string
            objectKey: string
            contentType: string
          }>(config[config.STAGE].endpoint + '/api/v1/generateUploadUrl', {
            folder: props.folderName + cognitoUser.getUsername(),
            filename: file.name,
            emailTo: email?.getValue(),
          })
          .then((res) => {
            // axiosだとEdgeでS3へのアップロードができなかったのでfetch APIを使用
            return fetch(res.data.presignedUrl, {
              method: 'PUT',
              body: file,
              headers: {
                'Content-Type': res.data.contentType,
              },
            }).then((response) => {
              if (config.STAGE === 'development') {
                console.log(response)
              }
            })
          })
      })
    )
      .then((res) => {
        setStatus('completed')
      })
      .catch((error) => {
        setStatus('error')
        if (config.STAGE === 'development') {
          console.error(error.body)
        }
      })
  }

  useEffect(() => {
    setStatus('initial')
    return
  }, [props.acceptedFiles, , props.folderName])

  console.log(props.fileRejections)

  return (
    <Dialog open={props.open} maxWidth="xl">
      <div style={{width: 800}}>
      <DialogContent>
        {(() => {
          const acceptedFilesList = (
            <>
              <p>以下のファイルをアップロードします。</p>
              <ul>
                {props.acceptedFiles.map((file, i) => (
                  <li key={i}>{file.name}</li>
                ))}
              </ul>
            </>
          )
          const fileRejectionsList = (
            <>
              <p>以下のファイルは、サポートされていないファイル形式のため、アップロードできません。</p>
              <ul>
                {props.fileRejections.map((fileRejection, i) => (
                  <li key={i}>{fileRejection.file.name}</li>
                ))}
              </ul>
            </>
          )
          switch (status) {
            case 'initial':
              return (
                <>
                  {props.acceptedFiles.length > 0 && acceptedFilesList}
                  {props.fileRejections.length > 0 && fileRejectionsList}
                </>
              )
            case 'uploading':
              return (
                <>
                  <p>アップロード中...</p>
                  <CircularProgress />
                </>
              )
            case 'completed':
              return <p>アップロードが完了しました。</p>
            case 'error':
              return <p>アップロードできなかったファイルがあります。</p>
          }
        })()}
      </DialogContent>
      <DialogActions>
        {(() => {
          switch (status) {
            case 'initial':
              return (
                <>
                  <Button autoFocus onClick={close} color="primary">
                    キャンセル
                  </Button>
                  {props.acceptedFiles.length > 0 && (
                    <Button onClick={onClickOK} color="primary">
                      Ok
                    </Button>
                  )}
                </>
              )
            case 'uploading':
              return <></>
            default:
              return (
                <Button onClick={close} color="primary">
                  閉じる
                </Button>
              )
          }
        })()}
      </DialogActions>
    </div>
    </Dialog>
  )
}

export default UploadDialog
